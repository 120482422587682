<template>
    <div class="view content-padding" :key="loopKey">
        <ContentContainer :order="{ body: 1, sidebar: 2 }">

            <template slot="body">
                <LabelInput
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :base-card="true"
                    :label="labels.title"
                    :value="definitionData.title"
                    @changed="dataChanged( { value: $event, key: 'title' } )"
                />
                <LabelInput
                    class="mb-4"
                    font-family="'Oswald', sans-serif"
                    :base-card="true"
                    :label="labels.display_title"
                    :value="definitionData.content_0"
                    @changed="dataChanged( { value: $event, key: 'content_0' } )"
                />
                <TextEditorCard
                    :title="labels.explanation_of_definition"
                    :value="definitionData.content_1"
                    @changed="dataChanged( { value: $event.value, key: 'content_1' } )"
                />
            </template>

            <template slot="sidebar">
                <LabelButtonCard
                    :header-title="labels.used_in"
                    :list="modulesList"
                    :no-data="labelBtnCardData.noData"
                />
            </template>

        </ContentContainer>
    </div>
</template>

<script>
import ContentContainer from "@/components/Containers/ContentContainer"
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import TextEditorCard from "@/components/Input/TextEditorCard";
import LabelInput from "@/components/Input/LabelInput";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customExternalLink } from "@/assets/icons";
library.add(customExternalLink);

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "Main",
    components: {
        ContentContainer,
        LabelInput,
        TextEditorCard,
        LabelButtonCard,
    },
    data: () => ({
        loopKey: Date.now(),
        labelBtnCardData: {
            noData: ''
        },
        modulesList: []
    }),
    computed: {
        ...mapGetters('helper', ['labels']),
        pageHeaderData() {
            let output = {
                preTitle: this.labels.definition_title,
                title: this.definitionTitle,
                btnList: [
                    {
                        type: 'button',
                        label: this.labels.save,
                        variant: 'btn-green',
                        class: 'btn-type-button',
                        action: this.saveDefinition,
                        disabled: this.countChanges.saveBtnDisabled
                    },
                ],
            };
            return output;
        },
        countChanges() {
            return this.$store.getters['card/countDefinitionChanges'];
        },
        stateDefinitionData() {
            return this.$store.state.card.stateDefinitionData
        },
        definitionData() {
            return this.$store.state.card.definitionData
        },
        definitionTitle() {
            if ( this.stateDefinitionData.title ) return this.stateDefinitionData.title;
            return '';
        },
    },
    methods: {
		...mapActions('notification', {
			addNotification: 'addNotification'
		}),
        ...mapMutations( "helper", {
            setPageHeaderData: "setPageHeaderData",
        }),
        ...mapMutations( "card", {
            updateDefinitionData: "updateDefinitionData",
            clearDefinitionData: 'clearDefinitionData',
        }),
        ...mapActions( "card", {
            getSingle: "getSingle",
            update: 'update',
        }),

        dataChanged( data ) {
            this.updateDefinitionData( { key: data.key, value: data.value } );
        },

        getCardData() {
            this.getSingle( { id: this.$route.params.id } )
            .then( res => {
                if ( res.success ) {
                    this.loopKey = Date.now();

                    res.data.modules.forEach( item => {
                        let obj = {
                            label: item.title,
                            _id: item._id,
                            actions: [
                                {type:"icon", icon:"custom-external-link", link:{name:'module-editor', params:{id: item._id}}}
                            ]
                        }
                        this.modulesList.push( obj );
                    } )
                    if ( !this.modulesList.length ) this.labelBtnCardData.noData = this.labels.no_data;
                    else this.labelBtnCardData.noData = '';
                } else if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }
            } )
            .catch( err => console.log( err ) );
        },

        saveDefinition() {
            let data = {
                id: this.$route.params.id,
            }
            this.countChanges.changedKeys.forEach( key => {
                data[key] = this.definitionData[key];
            } )
            this.update( data )
            .then( res => {
                if (res.success) {
                    console.log(res)
                    this.addNotification( { variant: 'success', msg: ['saved'], labels: this.labels })
                    this.getCardData();
                } else if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }
            })
            .catch( err => console.log( err ) );
        },
    },
    beforeMount() {
        this.setPageHeaderData({ preTitle: "", title: "", btnList: [] });
    },
    mounted() {
        this.getCardData();
        this.setPageHeaderData(this.pageHeaderData);
    },
    beforeDestroy() {
        this.setPageHeaderData({});
        this.clearDefinitionData();
    },
    watch: {
        pageHeaderData(value) {
            this.setPageHeaderData(value);
        }
    },
    beforeRouteLeave (to, from, next) {
        if( !this.countChanges.saveBtnDisabled ){
            const answer = window.confirm(this.labels.do_you_really_want_to_leave_you_have_unsaved_changes );
            if (answer) {
                // RESET CHANGES
                next()
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
};
</script>

<style lang="scss" scoped>

.view {

    ::v-deep {
        .label-button-card {
            &__header {
                text-transform: inherit;
            }
        }
    }
}

</style>
